body {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.dictionary-app {
  background-attachment: fixed;
  background-image: url('../src/components/assets/img/bakground_board_compress.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 0 10px 0;
  min-height: 100vh;
  height: 100%;
}

.dictionary-app .MuiGrid-spacing-xs-3 {
  width: calc(100% - 16px);
  margin: 0px 8px;
}

.MathJax {
  text-align: left!important;
}

.infinite-scroll-component .MuiTableCell-root, .infinite-scroll-component__outerdiv .MuiTableCell-root {
  width: 50%;
}